<template>
   <div class="m-p">
      <MyAccount />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Profile',
components: {
   MyAccount: defineAsyncComponent( () => import('@/components/Profile/MyAccount.vue') ),
},
}
</script>